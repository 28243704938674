import React, { FC } from 'react';
import { ProductsListProps } from './models';
import ProductsCards from './ProductsCards';
import ProductsCategories from './ProductsCategories';
import ProductsHeader from './ProductsHeader';

const ProductsList: FC<ProductsListProps> = ({
  productsCategories,
  productsCards,
  currentCategory,
  productsTitle,
  productsDescription,
  learnMoreLabel,
  showMenBannerCategory,
}) => {
  return (
    <div className="products-list" data-testid="products-list">
      <ProductsCategories {...{ productsCategories, currentCategory, showMenBannerCategory }} />
      {productsTitle || productsDescription ? (
        <ProductsHeader {...{ productsTitle, productsDescription }} />
      ) : null}
      <ProductsCards {...{ productsCards, learnMoreLabel, currentCategory }} />
    </div>
  );
};

export default ProductsList;
