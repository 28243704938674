import ProductsListPage from 'gatsby-theme-husky/src/templates/ProductsListPage/ProductsListPage';
import { graphql } from 'gatsby';

import 'gatsby-theme-husky/src/templates/ProductsListPage/productsList.scss';

export const query = graphql`
  query UmbracoProductListQuerys(
    $pageId: String
    $lang: String
    $variant: [String]
    $sortOrder: [SortOrderEnum]
    $sortName: [SalsifyProductsFieldsEnum]
  ) {
    allUmbracoProductsList(filter: { id: { eq: $pageId } }) {
      nodes {
        currentCategory: name
        productsTitle
        productsDescription
        learnMoreLabel
        cardsPerLoad
        sortProductsByName
        sortProductsName
        showMenBannerCategory
        showMenMainBanner
        loadMoreButton {
          text
          ariaLabel
        }
        langProps {
          urls {
            ...languageUrls
          }
          lang
        }
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
        }
        bannerImage {
          ...umbracoImage
        }
        bannerContent
        productsMainBanner {
          content
          bannerVariant
          imageAsBackgroundAlt
          imageAsBackground {
            fallbackUrl
            fluid {
              srcSet
              originalImg
              base64
            }
            variants {
              url
              type
              fallbackQuery
              alias
            }
          }
        }
        menMainBanner {
          content
          bannerVariant
          imageAsBackgroundAlt
          imageAsBackground {
            fallbackUrl
            fluid {
              srcSet
              originalImg
              base64
            }
            variants {
              url
              type
              fallbackQuery
              alias
            }
          }
        }
      }
    }
    productsListCategories: allUmbracoProductsList(filter: { langProps: { lang: { eq: $lang } } }) {
      productsCategories: nodes {
        ariaLabel
        name
        link
        hideInCategoriesList
      }
    }
    products: allSalsifyProducts(
      filter: {
        tags: { elemMatch: { name: { in: $variant } } }
        langProps: { lang: { eq: $lang } }
        showProduct: { nin: "0" }
      }
      sort: { fields: $sortName, order: $sortOrder }
    ) {
      productsCards: nodes {
        productTitle
        productTitleWithSize
        url
        productSize
        productImage
        productEAN
        showLanding {
          url
          name
        }
        localProductImage {
          childImageSharp {
            fixed {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        variantProductCards {
          title
          image
          size
          titleShort
          titleWithSize
          url
          ean
        }
      }
    }
  }
`;

export default ProductsListPage;
