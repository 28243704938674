import React, { FC } from 'react';
import ProductCard from 'gatsby-theme-husky/src/common/ProductCard';
import { ProductsCardsProps } from './models';

import './ProductsCards.scss';

const ProductsCards: FC<ProductsCardsProps> = ({
  productsCards,
  learnMoreLabel,
  currentCategory,
}) => (
  <div className="products-list__cards" data-testid="products-list-cards">
    {productsCards.map(
      ({
        productTitle,
        productTitleWithSize,
        productTitleShort,
        productSize,
        url,
        productImage,
        localProductImage,
        productEAN,
        variantProductCards,
        showLanding,
      }) => (
        <ProductCard
          key={`${productTitle}-${productImage}`}
          {...{
            productEAN,
            productTitle,
            productTitleWithSize,
            productTitleShort,
            productSize,
            url,
            productImage,
            localProductImage,
            learnMoreLabel,
            variantProductCards,
            currentCategory,
            showLanding,
          }}
        />
      )
    )}
  </div>
);

export default ProductsCards;
